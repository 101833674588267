import api from '@/api'

const state = {}

const mutations = {}

const actions = {
    getActions({ dispatch }, { id, type, search, filters, pagination }) {
        const params = { ...pagination, search, ...filters }

        return api
            .get(`/actions/${type}/${id}`, { params })
            .catch(e => dispatch('handleError', e))
    },
    getAction({ dispatch }, id) {
        return api
            .get(`/actions/${id}`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    getActionHistory({ dispatch }, { id, pagination, activity }) {
        const params = { ...pagination, activity }
        return api
            .get(`/actions/${id}/history`, { params })
            .catch(e => dispatch('handleError', e))
    },
    createAction({ dispatch }, action) {
        return api.post(`/actions`, { action }).catch(e => dispatch('handleError', e))
    },
    updateAction({ dispatch }, action) {
        return api
            .patch(`/actions/${action.id}`, { action })
            .catch(e => dispatch('handleError', e))
    },
    deleteAction({ dispatch }, id) {
        return api.delete(`/actions/${id}`).catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
