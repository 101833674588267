<script>
export default {
    name: 'k-list',
    inheritAttrs: false,
    props: {
        horizontal: { type: Boolean, required: false },
    },
}
</script>

<template>
    <v-list
        v-bind="$attrs"
        v-on="$listeners"
        class="k-list"
        :class="{ 'k-list--horizontal': horizontal }">
        <slot />
    </v-list>
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/settings/_variables';

.k-list::v-deep {
    .v-list-item {
        &__subtitle {
            line-height: 28px;
        }
    }
}
.k-list--horizontal::v-deep {
    margin: 0 -12px;
    .v-list-item {
        padding: 0 28px;
    }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .k-list--horizontal::v-deep {
        display: flex;
        flex-wrap: wrap;
        .v-list-item {
            min-width: 192px;
            max-width: 192px;
        }
    }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
    .k-list--horizontal::v-deep {
        .v-list-item {
            &__title {
                justify-content: space-between;
            }
        }
    }
}
</style>
