<script>
export default {
    page: {
        title: '404',
        meta: [{ name: 'description', content: '404' }],
    },
}
</script>

<template>
    <v-container fluid>
        <v-row no-gutters class="justify-center">
            <v-col cols="12" sm="12" md="6" lg="5" xl="3">
                <img
                    src="@/assets/images/404.svg"
                    srcset="@/assets/images/404.svg"
                    style="max-width: 100%" />
            </v-col>
        </v-row>
        <v-row no-gutters class="align-space-around justify-space-around">
            <v-col>
                <h1 class="text-center red-text">
                    {{ $t("Oops! We couldn't find the page you are looking for!") }}
                </h1>
                <p class="text-center">
                    {{
                        $t(
                            'If you think that there may be some problem on KINCHAKU side, please contact our helpdesk.'
                        )
                    }}
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>
