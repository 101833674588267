export const TIME_DURATION_REGEX = /(\d+)\s+(\w+)/

export const UNIT_OF_TIME = {
    minutes: {
        maxCount: 90,
        label: 'minutes|minute|minutes',
        step: 15,
        additional: [5, 10],
        inStepSeconds: 60,
    },
    hours: { maxCount: 23, label: 'hours|hour|hours', step: 1, inStepSeconds: 60 * 60 },
    days: { maxCount: 30, label: 'days|day|days', step: 1, inStepSeconds: 60 * 60 * 24 },
    weeks: {
        maxCount: 4,
        label: 'weeks|week|weeks',
        step: 1,
        inStepSeconds: 60 * 60 * 24 * 7,
    },
    months: {
        maxCount: 6,
        label: 'months|month|months',
        step: 1,
        inStepSeconds: 60 * 60 * 24 * 7 * 30,
    },
}

export const getTimeDurationObjectByString = value => {
    const timeDurationParts = TIME_DURATION_REGEX.exec(value.replace('-', ''))

    if (!timeDurationParts) {
        // eslint-disable-next-line
        console.warn('Unknown time duration format')
        return { count: 0, unitOfTime: 'minutes' }
    }
    const count = +timeDurationParts[1]

    let unitOfTime = timeDurationParts[2]
    unitOfTime = unitOfTime.slice(-1) === 's' ? unitOfTime : unitOfTime + 's'

    return { count, unitOfTime }
}

export const isDurationMoreThan = (a, b) => {
    if (!a || !b) return true

    const aObject = typeof a === 'object' ? a : getTimeDurationObjectByString(a)
    const bObject = typeof b === 'object' ? b : getTimeDurationObjectByString(b)

    if (aObject.unitOfTime === bObject.unitOfTime) {
        return aObject.count > bObject.count
    }

    return (
        UNIT_OF_TIME[aObject.unitOfTime].inStepSeconds * aObject.count >
        UNIT_OF_TIME[bObject.unitOfTime].inStepSeconds * bObject.count
    )
}
