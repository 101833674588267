<script>
export default {
    name: 'KDialog',
    props: {
        width: {
            type: [Number, String],
        },
        maxWidth: {
            type: [Number, String],
        },
        value: {
            default: false,
        },
        fullscreen: {
            default: false,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            default: false,
        },
    },
    data() {
        return {
            proxy: this.value,
            windowWidth: window.innerWidth,
        }
    },
    computed: {
        model: {
            get() {
                return this.proxy
            },
            set(value) {
                if (value == null) {
                    return
                }
                if (
                    value.constructor
                        .toString()
                        .match(/function (\w*)/)[1]
                        .toLowerCase() !== 'inputevent'
                ) {
                    this.$nextTick(() => {
                        this.proxy = value
                    })
                }
            },
        },
        useFullscreen() {
            return this.fullscreen || this.$vuetify.breakpoint.smAndDown
        },
    },
    watch: {
        value(val) {
            this.proxy = val
        },
        proxy(isActive) {
            this.$nextTick().then(() => {
                if (isActive) {
                    this.$emit('dialog-opened')
                } else {
                    this.$emit('dialog-closed')
                }
                this.$emit('input', isActive)
            })
        },
    },
    mounted() {
        this.proxy = this.value
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
        window.addEventListener('keydown', this.onKeyDown)
    },
    beforeDestroy() {
        //do something before destroying vue instance
        window.removeEventListener('keydown', this.onKeyDown)
    },
    methods: {
        onKeyDown(event) {
            // `keyCode` is present in almost all browsers, but is deprecated in the spec.
            const key = event.key || event.keyCode
            // IE11 uses “Esc” rather than “Escape”
            if (key === 'Escape' || key === 'Esc' || key === 27) {
                this.model = false
                this.$emit('close')
            }
        },
    },
}
</script>
<template>
    <v-dialog
        v-model="model"
        v-on="$listeners"
        :fullscreen="useFullscreen"
        :width="width"
        :max-width="maxWidth"
        :persistent="persistent"
        :scrollable="scrollable">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </v-dialog>
</template>
