<script>
import { groupBy } from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState({
            venues: state => state.venues.list,
        }),
        ...mapGetters(['workspace_flags']),
        venue() {
            return this.selected_venue
        },
        with_facilities() {
            return this.workspace_flags.with_facilities
        },
        venue_items() {
            if (!this.with_facilities) return this.venues || []
            const venuesGroup = groupBy(this.venues, venue => venue.parent_id)
            return Object.keys(venuesGroup).reduce((venuesItems, parrentId) => {
                if (parrentId && parrentId !== 'null' && parrentId !== 'undefined') {
                    const facility = this.venues.find(venue => venue.id === parrentId)
                    if (facility) {
                        venuesItems.push({ ...facility }, ...venuesGroup[parrentId])
                    }
                }
                return venuesItems
            }, [])
        },
    },

    methods: {
        ...mapActions(['selectVenue']),
    },
}
</script>

<template>
    <div v-if="venue_items.length > 1">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="w-100 justify-space-between mb-6"
                    height="64px"
                    text>
                    <div class="d-flex align-center">
                        <template v-if="venue">{{ venue.title }}</template>
                        <template v-else>{{ $t('Select venue') }}</template>
                    </div>
                    <v-icon class="rot-90">chevron_right</v-icon>
                </v-btn>
            </template>
            <v-list style="max-height: 200px; overflow-y: auto">
                <v-list-item
                    v-for="venue in venue_items"
                    @click="selectVenue(venue)"
                    :disabled="with_facilities && !venue.parent_id"
                    :key="venue.id">
                    <v-list-item-icon class="mr-3">
                        <v-icon>
                            {{ with_facilities && !venue.parent_id ? 'icon-map' : 'place' }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ venue.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
