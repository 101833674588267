<script>
import KDialog from '@/components/dialog.vue'
// TODO: confirm by enter
export default {
    components: { KDialog },
    name: 'KConfirm',
    props: {
        maxWidth: {
            type: [Number, String],
        },
        title: String,
        question: String,
        danger: {
            type: Boolean,
            default: true,
        },
        disabled: Boolean,
        active: {
            type: Boolean,
            default: undefined,
        },
        confirmText: String,
        cancelText: String,
        noTranslate: Boolean,
    },
    data() {
        return {
            show: false,
        }
    },
    created() {
        if (this.active) this.show = this.active
    },
    watch: {
        active() {
            this.show = this.active
        },
        show() {
            if (!this.show) this.$emit('close')
        },
    },
    computed: {
        color() {
            return this.danger ? 'error' : 'primary'
        },
        confirm_text() {
            return this.confirmText || this.$t('confirm')
        },
        cancel_text() {
            return this.cancelText || this.$t('cancel')
        },
    },
}
</script>

<template>
    <k-dialog v-model="show" persistent :max-width="maxWidth">
        <template v-if="active === undefined" #activator="slotData">
            <slot name="button" v-bind="slotData">
                <v-btn v-on="slotData.on" text outlined color="primary">
                    <slot name="button-text">{{ $t('confirm') }}</slot>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="text-h5">
                <slot name="title">{{ $t(title) }}</slot>
            </v-card-title>
            <v-card-text class="py-0">
                <slot name="question">{{ noTranslate ? question : $t(question) }}</slot>
            </v-card-text>
            <v-card-actions class="pa-4">
                <v-spacer />
                <v-btn @click="show = false" color="primary" class="btn-danger-hover" text>
                    {{ cancel_text }}
                </v-btn>
                <v-btn
                    :color="color"
                    :disabled="disabled"
                    @click="
                        $emit('confirm')
                        show = false
                    ">
                    {{ confirm_text }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </k-dialog>
</template>
