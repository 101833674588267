<script>
import KManageColumns from '@/components/_base/KManageColumns.vue'

export default {
    components: { KManageColumns },
    props: {
        items: { type: Array, default: () => [] },
        headers: { type: Array, default: () => [] },
        title: String,
        options: Object,
        hideToolbar: Boolean,
        withColumnsManager: Boolean,
        customManageColumns: Boolean,
        manageColumnsIconColor: String,
        tableId: String,
        footerProps: Object,
        serverItemsLength: Number,
        showSelect: Boolean,
        titleClass: String,
    },
    data() {
        return {
            localSearch: '',
            displayHeaders: [],
        }
    },
    watch: {
        localSearch(val) {
            this.$emit('update:search', val)
        },
        displayHeaders(val) {
            this.$emit('change-display-headers', val)
        },
        headers(val) {
            this.displayHeaders = val
        },
    },
    mounted() {
        this.localSearch = this.$attrs.search
        if (this.customManageColumns || this.$slots['title']) this.displayHeaders = this.headers
    },
    computed: {
        has_items() {
            return this.items && this.items.length
        },
        show_columns_manager() {
            return (
                this.has_items &&
                this.headers &&
                (Object.prototype.hasOwnProperty.call(this.$listeners, 'change-display-headers') ||
                    this.withColumnsManager)
            )
        },
        show_columns_manager_in_toolbar() {
            return this.show_columns_manager && !this.title
        },
        show_toolbar() {
            return (
                !this.hideToolbar &&
                (Object.prototype.hasOwnProperty.call(this.$attrs, 'search') ||
                    this.show_columns_manager_in_toolbar ||
                    this.$slots.filters ||
                    this.$slots['toolbar-top'])
            )
        },
        has_search() {
            return Object.prototype.hasOwnProperty.call(this.$attrs, 'search')
        },
    },
}
</script>

<template>
    <v-card flat class="k-card">
        <slot v-if="$slots['title']" name="title" />
        <k-card-title v-else-if="title" class="pa-0 mb-4">
            <div class="d-flex align-center w-100 flex-wrap">
                <div class="mr-3" :class="titleClass">{{ title }}</div>
                <slot v-if="$slots['title-right']" name="title-right" />
                <v-spacer v-show="show_columns_manager" v-if="!$slots['title-right']" />
                <div v-show="show_columns_manager">
                    <k-manage-columns
                        :iconColor="manageColumnsIconColor"
                        :columns="headers || []"
                        :tableId="tableId"
                        @change="columns => (displayHeaders = columns)"
                        class="ml-3" />
                </div>
            </div>
        </k-card-title>

        <v-card-actions
            v-if="$slots['toolbar']"
            flat
            :class="{ 'blue-grey lighten-5': !$root.dark }"
            class="k-table-toolbar">
            <slot name="toolbar" />
        </v-card-actions>
        <v-card-actions
            v-else-if="show_toolbar"
            flat
            :class="{ 'blue-grey lighten-5': !$root.dark }"
            class="k-table-toolbar">
            <slot v-if="$slots['toolbar-top']" name="toolbar-top" />
            <v-row align="center" class="ma-0">
                <slot v-if="$slots.search" name="search" />
                <v-col
                    v-else-if="has_search"
                    :cols="$slots.filters ? 12 : 'auto'"
                    sm="4"
                    lg="3"
                    xl="2">
                    <k-text-field
                        v-model="localSearch"
                        append-icon="search"
                        :label="$t('search')"
                        clearable
                        hide-details
                        dense
                        outlined
                        background-color="white" />
                </v-col>

                <slot v-if="$slots.filters" name="filters" />

                <slot v-if="$slots['manage-columns']" name="manage-columns" />
                <v-spacer
                    v-show="
                        !$slots['manage-columns'] &&
                        show_columns_manager_in_toolbar &&
                        !$slots.filters
                    " />
                <v-col
                    v-show="!$slots['manage-columns'] && show_columns_manager_in_toolbar"
                    cols="auto">
                    <k-manage-columns
                        :iconColor="manageColumnsIconColor"
                        :columns="headers || []"
                        :tableId="tableId"
                        @change="columns => (displayHeaders = columns)" />
                </v-col>
            </v-row>
        </v-card-actions>
        <k-data-table
            class="k-table"
            v-bind="$attrs"
            v-on="$listeners"
            :items="items"
            :headers="displayHeaders"
            :footer-props="footerProps"
            :options="options"
            :mobile-breakpoint="0"
            :server-items-length="serverItemsLength"
            :show-select="showSelect">
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope" />
            </template>
        </k-data-table>
        <slot name="after-footer" />
    </v-card>
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/styles.sass';

.k-card {
    background-color: map-get($shades, 'white');
    border: 1px solid map-get($grey, 'lighten-2');
    border-radius: $spacer;
    padding: $spacer * 4;
    box-shadow: none;
}
.k-table-toolbar {
    display: block;
    padding: 4px;
}
</style>
