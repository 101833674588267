import api from '@/api'

const state = {
    list: [],
}

const mutations = {
    RESET(state) {
        state.list = []
    },
    SET_INTEGRATIONS(state, data) {
        state.list = data
    },
}

const actions = {
    getIntegrations({ commit, dispatch }) {
        return api
            .get('/integrations')
            .then(({ data }) => {
                commit('SET_INTEGRATIONS', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    getAccountIntegrations({ dispatch }) {
        return api
            .get('/integrations/account')
            .then(({ data }) => {
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    sendTestWebhook({ dispatch }) {
        return api
            .post('/integrations/webhook/test', {})
            .catch(e => dispatch('handleError', e))
    },
    createIntegration({ dispatch }, { type, service }) {
        return api
            .post(`/integrations/api/${type}`, { service })
            .then(() => {
                dispatch('getIntegrations')
            })
            .catch(e => dispatch('handleError', e))
    },
    updateIntegration({ dispatch }, { type, service }) {
        return api
            .patch(`/integrations/api/${type}`, {
                service,
            })
            .then(() => {
                dispatch('getIntegrations')
            })
            .catch(e => dispatch('handleError', e))
    },
    validateIntegration({ dispatch }, type) {
        return api
            .put(`/integrations/api/${type}`, {})
            .then(({ result }) => {
                return result
            })
            .catch(e => dispatch('handleError', e))
    },
    removeIntegration({ dispatch }, id) {
        return api.delete(`/integrations/${id}`, {}).catch(e => dispatch('handleError', e))
    },
    generateAPIKey({ dispatch }) {
        return api
            .post(`/integrations/api`, {})
            .then(() => {
                dispatch('getIntegrations')
            })
            .catch(e => dispatch('handleError', e))
    },
    exportAccessLogAsCSV({ dispatch }) {
        return api
            .post(`/integrations/api/access-log/export`)
            .then(({ location }) => location)
            .catch(e => dispatch('handleError', e))
    },
    saveApiDetails({ dispatch }, details) {
        return api
            .patch('/integrations/api', details)
            .then(() => dispatch('getIntegrations'))
            .catch(e => dispatch('handleError', e))
    },
    saveStripe({ dispatch }, stripe) {
        return api
            .post(`/integrations/stripe`, { stripe })
            .then(() => {
                dispatch('getIntegrations')
            })
            .catch(e => dispatch('handleError', e))
    },
    importIntegration({ dispatch }, service) {
        return api
            .post(`/integrations/${service}/import`)
            .catch(e => dispatch('handleError', e))
    },
    importSquareContacts({ dispatch }) {
        return api
            .post(`/integrations/square/import-contacts`)
            .catch(e => dispatch('handleError', e))
    },
    getRemotelockDevices({ dispatch }, params) {
        return api
            .get('/integrations/remotelock/devices', { params })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    getRemotelockDevice({ dispatch }, device) {
        return api
            .get(`/integrations/remotelock/devices/${device}`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    importPurolandSummaryFromJSON({ dispatch }, params = {}) {
        const { file } = params
        const formData = new FormData()
        formData.append('file', file)
        return api
            .request({
                url: `/integrations/puroland/upload`,
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
            })
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
