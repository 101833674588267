import api from '@/api'
import omitBy from 'lodash/omitBy'
import omit from 'lodash/omit'

const state = {}

const getters = {}

const mutations = {}

const actions = {
    // PASSES
    getIssuedPasses({ dispatch }, params = {}) {
        let { pagination, id, filters = {} } = params
        const definedFilters = omitBy(filters, v => !v)
        return api
            .get(`/wallet/projects/${id}/passes`, {
                params: { ...definedFilters, ...pagination },
            })
            .then(data => data)
            .catch(e => dispatch('handleError', e))
    },

    issuePasses({ dispatch }, { id, plan = null, data }) {
        let formData

        if (data.contact?.picture || data.contact?.face_photo || data.contact?.id_document) {
            formData = new FormData()

            for (const dataKey in data) {
                if (dataKey === 'contact') {
                    if (!data['contact']) continue

                    if (data.contact.picture)
                        formData.append('contact_picture', data.contact.picture)
                    if (data.contact.face_photo)
                        formData.append('contact_picture', data.contact.face_photo)
                    if (data.contact.id_document) {
                        formData.append('id_document', data.contact.id_document)
                    }

                    formData.append(
                        'contact',
                        JSON.stringify(
                            omit(
                                omitBy(data.contact, v => v === null),
                                ['picture', 'face_photo', 'id_document']
                            )
                        )
                    )
                } else if (dataKey === 'booking') {
                    for (const key in data.booking) {
                        if (data.booking[key] !== undefined) {
                            formData.append(`booking[${key}]`, data.booking[key])
                        }
                    }
                } else {
                    if (data[dataKey] !== undefined) {
                        formData.append(
                            dataKey,
                            typeof data[dataKey] === 'object'
                                ? JSON.stringify(data[dataKey])
                                : data[dataKey]
                        )
                    }
                }
            }
        }
        const url = plan ? `/wallet/${id}/plans/${plan}/pay` : `/wallet/projects/${id}/issue`

        return api
            .request({
                url,
                method: 'post',
                headers: {
                    'Content-Type': formData ? 'multipart/form-data' : 'application/json',
                },
                data: formData || data,
            })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },

    getPassDetails({ dispatch }, id) {
        return api
            .get(`/wallet/${id}`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    getPassOperations({ dispatch }, { id, pagination, venue }) {
        return api
            .get(`/wallet/${id}/activity`, { params: { ...pagination, venue } })
            .catch(e => dispatch('handleError', e))
    },
    getPassUpdates({ dispatch }, { id, pagination }) {
        return api
            .get(`/wallet/${id}/updates`, { params: { ...pagination, with_history: 1 } })
            .catch(e => dispatch('handleError', e))
    },
    exportTimeTracking({ dispatch }, params = {}) {
        const { id, selectedColumns = null, start, end, interval } = params
        return api
            .post(`/wallet/projects/${id}/timetracking/export`, {
                selectedColumns,
                start,
                end,
                interval,
            })
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
    exportPassesSummary({ dispatch }, id) {
        return api
            .post(`/wallet/projects/${id}/summary/export`)
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
    redeemPass({ dispatch }, { id, reward, venue, quantity }) {
        return api
            .patch(`/wallet/${id}/redeem`, { reward, venue, quantity })
            .catch(e => dispatch('handleError', e))
    },
    forceUpdatePass({ dispatch }, { id }) {
        return api.patch(`/wallet/${id}/force-update`).catch(e => dispatch('handleError', e))
    },
    refundPass({ dispatch }, { passId, amount, reason }) {
        return api
            .delete(`/wallet/${passId}/refund`, { params: { amount, reason } })
            .catch(e => dispatch('handleError', e))
    },

    saveMemberPoints({ dispatch }, { id, value, description, venue, aux_field = null }) {
        return api
            .put(`/wallet/${id}/value`, { value, description, venue, aux_field })
            .catch(e => dispatch('handleError', e))
    },
    redeemPointRewards({ dispatch }, { id, rewards, venue, description, aux_field = null }) {
        return api
            .patch(`/wallet/${id}/redeem`, { rewards, venue, description, aux_field })
            .catch(e => dispatch('handleError', e))
    },
    punchStampCard({ dispatch }, { id, value, redeem, venue }) {
        return api
            .patch(`/wallet/${id}/punch`, { value, redeem, venue })
            .catch(e => dispatch('handleError', e))
    },

    checkinPass({ dispatch }, { id, venue, tag, aux_field, persons }) {
        return api
            .patch(`/wallet/${id}/checkin`, { venue, tag, aux_field, persons })
            .catch(e => dispatch('handleError', e))
    },
    checkoutPass({ dispatch }, { id, venue }) {
        return api
            .patch(`/wallet/${id}/checkout`, { venue })
            .catch(e => dispatch('handleError', e))
    },
    voidPass({ dispatch }, id) {
        return api.delete(`/wallet/${id}/void`).catch(e => dispatch('handleError', e))
    },
    deletePass({ dispatch }, id) {
        return api.delete(`/wallet/${id}`).catch(e => dispatch('handleError', e))
    },
    resendPassEmail({ dispatch }, id) {
        return api.patch(`/wallet/${id}/resend`).catch(e => dispatch('handleError', e))
    },
    rotateRemotelockPin({ dispatch }, id) {
        return api.patch(`/wallet/${id}/remotelock`).catch(e => dispatch('handleError', e))
    },
    togglePassSubscription({ dispatch }, id) {
        return api.patch(`/wallet/${id}/subscription`).catch(e => dispatch('handleError', e))
    },
    voidPasses({ dispatch }, params) {
        return api.patch('/wallet/bulk/void', params).catch(e => dispatch('handleError', e))
    },
    extendExpiration({ dispatch }, params) {
        return api.patch('/wallet/bulk/extend', params).catch(e => dispatch('handleError', e))
    },
    updatePassExpiration({ dispatch }, { id, expires_at }) {
        return api
            .patch(`/wallet/${id}/expiration`, { expires_at })
            .catch(e => dispatch('handleError', e))
    },
    updatePassBooking({ dispatch }, { id, booking }) {
        return api
            .patch(`/wallet/${id}/booking`, { booking })
            .catch(e => dispatch('handleError', e))
    },
    generatePass({ dispatch }, pass) {
        return api.post('/wallet/generate', pass).catch(e => dispatch('handleError', e))
    },
    toggleProjectSubscription({ dispatch }, { id, resume }) {
        return api
            .patch(`wallet/projects/${id}/subscription`, { resume })
            .catch(e => dispatch('handleError', e))
    },

    voidWalletPass({ dispatch }, id) {
        return api.delete(`/wallet/${id}/void`).catch(e => dispatch('handleError', e))
    },

    passSearch({ dispatch }, search) {
        return api
            .get('/wallet/passes/search', { params: { search } })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    getters,
    mutations,
}
