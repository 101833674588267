<script>
import { mapState, mapActions } from 'vuex'

import { baseURL } from '@/api'

export default {
    props: {
        withSmall: Boolean,
        onlyMultiple: Boolean,
    },
    computed: {
        ...mapState({
            user: state => state.account.user,
            workspaces: state => state.workspaces.list,
        }),
        workspace() {
            return this.user.origin ? this.user : null
        },
        workspace_logo() {
            if (this.user?.settings?.logo_path) {
                return `${baseURL}/user/${this.user.id}/branding/logo`
            }

            return null
        },
        venue() {
            return this.selected_venue
        },
        origin_user() {
            return this.user.origin ? this.user.origin : this.user
        },
    },
    methods: {
        ...mapActions(['lockWorkspace', 'switchContext']),
        switchTo(workspace) {
            let workspaceId = workspace?.id || this.origin_user.id

            if (workspaceId == this.user.id) return

            this.switchContext(workspaceId).then(() => {
                window.location = '/'
            })
        },
        newWorkspace() {
            if (this.user.origin?.id) {
                this.switchContext(this.user.origin.id).then(() => window.location.reload())
            }
        },
    },
}
</script>

<template>
    <div v-if="onlyMultiple ? workspaces.length > 1 : workspaces.length">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="w-100 justify-space-between"
                    height="64px"
                    text>
                    <div class="d-flex align-center">
                        <template v-if="workspace">
                            <img :src="workspace_logo" class="mr-2 rounded-full" height="40px" />
                            <span :class="{ 'd-none d-sm-inline': withSmall }">
                                {{ workspace.name }}
                            </span>
                        </template>
                        <template v-else>{{ $t('Select workspace') }}</template>
                    </div>
                    <v-icon :class="{ 'd-none d-sm-block': withSmall }" class="rot-90">
                        chevron_right
                    </v-icon>
                </v-btn>
            </template>
            <v-list style="max-height: 200px; overflow-y: auto">
                <v-list-item
                    v-for="workspace in workspaces"
                    @click="switchTo(workspace)"
                    :key="workspace.id">
                    <v-list-item-title>{{ workspace.name }}</v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item @click="newWorkspace">
                    <v-icon>add</v-icon>
                    &nbsp;&nbsp;
                    <span class="text-capitalize">{{ $t('new workspace') }}</span>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
