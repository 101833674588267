import api from '@/api'

const state = {}

const mutations = {}

const actions = {
    createMessage({ dispatch }, message) {
        return api.post(`/messages`, { message }).catch(e => dispatch('handleError', e))
    },
    getMessageCost({ dispatch }, message) {
        return api
            .post(`/messages/cost`, { message })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    getMessage({ dispatch }, id) {
        return api
            .get(`/messages/${id}`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    getMessages({ dispatch }, { id, type, search, filters, pagination }) {
        const params = { ...pagination, search, ...filters }
        return api
            .get(`/messages/${type}/${id}`, { params })
            .catch(e => dispatch('handleError', e))
    },
    updateMessage({ dispatch }, message) {
        return api
            .patch(`/messages/${message.id}`, { message })
            .catch(e => dispatch('handleError', e))
    },
    deleteMessage({ dispatch }, id) {
        return api.delete(`/messages/${id}`).catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
