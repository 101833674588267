<script>
export default {
    data() {
        return {
            showConsentDialog: false,
            showDetailsDialog: false,
            formData: {
                withEssential: true,
                withPerformance: false,
            },
        }
    },
    mounted() {
        this.checkConsentTimeout()
    },
    methods: {
        checkConsent() {
            try {
                if (localStorage.getItem('cookieConsent') === null) {
                    this.showConsentDialog = true
                }
            } catch (error) {
                // eslint-disable-next-line
                console.warn(error)
            }
        },
        checkConsentTimeout(counter = 0) {
            setTimeout(() => {
                if (counter > 100) return

                if (this.$matomo) {
                    this.checkConsent()
                } else {
                    this.checkConsentTimeout(++counter)
                }
            }, 100)
        },
        acceptAll() {
            this.formData.withPerformance = true
            this.showConsentDialog = false

            localStorage.setItem('cookieConsent', true)

            this.$matomo.rememberConsentGiven()
        },
        confirm() {
            this.showConsentDialog = false

            if (this.formData.withPerformance) {
                localStorage.setItem('cookieConsent', true)
                this.$matomo.rememberConsentGiven()
            } else {
                localStorage.setItem('cookieConsent', false)
                this.$matomo.forgetConsentGiven()
            }
        },
    },
}
</script>

<template>
    <div>
        <k-dialog v-model="showConsentDialog" persistent max-width="600">
            <v-card>
                <v-card-title>{{ $t('This website uses cookies') }}</v-card-title>
                <v-card-text>
                    <template v-if="$root.is_japanese">
                        当社は、以下の目的で Cookie および同様のツール (以下総称して「Cookie」)
                        を使用いたします。
                    </template>
                    <template v-else>
                        We use cookies and similar tools (collectively, "cookies") for the following
                        purposes.
                    </template>

                    <v-list flat two-line class="mt-3">
                        <v-list-item :ripple="false" :dense="true" class="blue-grey lighten-5">
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox
                                        v-model="formData.withEssential"
                                        :input-value="active"
                                        hide-details
                                        disabled></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class="text-capitalize font-weight-bold">
                                        essential cookie
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <template v-if="$root.is_japanese">
                                            <small>
                                                当社のサイトおよびサービスを提供するために必要であり、無効にすることはできません。
                                            </small>
                                        </template>
                                        <template v-else>
                                            <small>
                                                Essential cookies are necessary to provide our site
                                                and services and cannot be deactivated.
                                            </small>
                                        </template>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list>
                    <v-list flat three-line>
                        <v-list-item :ripple="false" :dense="true">
                            <template>
                                <v-list-item-action>
                                    <v-checkbox
                                        v-model="formData.withPerformance"
                                        hide-details></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class="text-capitalize font-weight-bold">
                                        performance cookie
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <template v-if="$root.is_japanese">
                                            <small>
                                                お客様によるサイトの操作方法に関する匿名の統計を提供するため、
                                                サイトの使い勝手とパフォーマンスを向上させることができます。
                                            </small>
                                        </template>
                                        <template v-else>
                                            <small>
                                                Performance cookies provide anonymous statistics
                                                about how customers navigate our site so we can
                                                improve site experience and performance.
                                            </small>
                                        </template>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <template v-if="$root.is_japanese">
                                            <small>
                                                承認された第三者は、当社に代わって分析を行う場合がありますが、データを独自の目的で使用することはできません。
                                            </small>
                                        </template>
                                        <template v-else>
                                            <small>
                                                Approved third parties may perform analytics on our
                                                behalf, but they cannot use the data for their own
                                                purposes.
                                            </small>
                                        </template>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-spacer />
                    <v-btn @click="confirm">{{ $t('confirm') }}</v-btn>
                    <v-btn color="primary" @click="acceptAll">{{ $t('accept all') }}</v-btn>
                </v-card-actions>
            </v-card>
        </k-dialog>
    </div>
</template>
