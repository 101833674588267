<script>
import { format, isValid, isWithinRange, isAfter, isBefore, isSameDay } from 'date-fns'

import { parse } from '@/utils/date.js'

export default {
    props: {
        mode: { type: String, required: false, default: 'date' },
        value: { type: [Date, String], required: false },
        label: { type: String, required: false },
        rules: { type: Array, required: false, default: () => [] },
        placeholder: { type: String, required: false },
        hint: { type: String, required: false },
        persistentHint: { type: Boolean, required: false },
        minDate: { type: Date, required: false },
        maxDate: { type: Date, required: false },
        prependIcon: { type: String, required: false },
        clearable: { type: Boolean, required: false },
        disabled: { type: Boolean, required: false },
        fastValid: { type: Boolean, required: false },
        persistentPlaceholder: { type: Boolean, required: false },
        items: { type: Array, required: false },
        itemsText: { type: String, required: false },
        allowedDates: { type: Array, required: false },
        outputDateFormat: { type: String, default: 'YYYY-MM-DD' },
    },
    data() {
        return {
            showDatePicker: false,
            showTimePicker: false,
            localValue: '',
            dateLocalValue: null,
            timeLocalValue: null,
            showItems: false,
            focus: false,
            dirty: false,
        }
    },
    created() {
        this.changeLocalByValue()
    },
    watch: {
        value() {
            this.changeLocalByValue()
        },
        localValue() {
            if (!this.localValue) {
                return this.$emit('input', this.localValue)
            }

            if (this.mode === 'time') {
                if (this.checkValidTime(this.localValue)) {
                    return this.$emit('input', this.localValue)
                }
            } else if (this.mode === 'datetime') {
                if (this.checkValidDateTime(this.localValue)) {
                    return this.$emit(
                        'input',
                        format(parse(this.localValue), this.outputDateFormat + ' HH:mm')
                    )
                }
            } else {
                if (this.checkValidDate(this.localValue)) {
                    return this.$emit(
                        'input',
                        format(parse(this.localValue), this.outputDateFormat)
                    )
                }
            }
        },
        is_japanese() {
            if (this.mode === 'date') {
                this.localValue = format(parse(this.localValue), this.date_format)
            }

            if (this.mode === 'datetime') {
                this.localValue = format(parse(this.localValue), this.date_format + ' HH:mm')
            }
        },
    },
    computed: {
        is_japanese() {
            return this.$root.is_japanese
        },
        date_format() {
            return this.is_japanese ? 'YYYY/MM/DD' : 'DD.MM.YYYY'
        },
        local_rules() {
            if (this.mode === 'time') {
                return [v => !v || this.checkValidTime(v) || this.$t('Please enter a valid time')]
            } else if (this.mode === 'datetime') {
                return [
                    v =>
                        !v ||
                        (this.checkValidDateTime(v) && this.checkDatePeriod(v)) ||
                        this.$t('Please enter a valid date and time'),
                ]
            } else {
                return [
                    v => {
                        return (
                            !v ||
                            (this.checkValidDate(v) && this.checkDatePeriod(v)) ||
                            this.$t('Please enter a valid date')
                        )
                    },
                ]
            }
        },
        mask() {
            if (this.mode === 'time') {
                return '##:##'
            } else if (this.mode === 'datetime') {
                if (this.is_japanese) return '####/##/## ##:##'
                return '##.##.#### ##:##'
            } else {
                if (this.is_japanese) return '####/##/##'
                return '##.##.####'
            }
        },
        field_placeholder() {
            if (this.placeholder) return this.placeholder

            if (this.mode === 'time') return 'HH:MM'

            if (this.mode === 'datetime') {
                if (this.is_japanese) return 'YYYY/MM/DD HH:mm'
                return 'DD.MM.YYYY HH:mm'
            }

            if (this.is_japanese) return 'YYYY/MM/DD'
            return 'DD.MM.YYYY'
        },
        min_date() {
            if (!this.minDate) return undefined

            return format(parse(this.minDate), 'YYYY-MM-DD')
        },
        max_date() {
            if (!this.maxDate) return undefined

            return format(parse(this.maxDate), 'YYYY-MM-DD')
        },
        maxlength() {
            if (this.mode === 'time') return 5
            if (this.mode === 'datetime') return 16
            return 10
        },
        label_text() {
            if (!this.label) return undefined

            let label = this.label

            if ((this.rules || []).find(r => r.name === 'required')) label += ' *'

            return label
        },
    },
    methods: {
        changeLocalByValue() {
            if (!this.value) return

            if (this.mode === 'time') {
                this.localValue = this.value
                this.timeLocalValue = this.value
            } else {
                const dateValue = parse(this.value)
                const withTime = this.mode === 'datetime' ? ' HH:mm' : ''

                if (!isValid(dateValue)) return

                this.dateLocalValue = format(dateValue, this.outputDateFormat + withTime)
                this.localValue = format(this.dateLocalValue, this.date_format + withTime)

                if (this.mode !== 'date') this.timeLocalValue = format(dateValue, 'HH:mm')
            }
        },
        onDatePickerChange(value) {
            if (this.mode === 'datetime') {
                this.showDatePicker = false
                this.showTimePicker = true
                this.localValue =
                    format(value, this.date_format) + ` ${this.timeLocalValue || '00:00'}`
            } else {
                this.showDatePicker = false
                this.localValue = format(value, this.date_format)
            }
        },
        checkValidDate(date) {
            if (this.is_japanese) {
                return /\d{4}\/\d{2}\/\d{2}/.test(date) && isValid(new Date(date))
            }

            return /\d{2}.\d{2}.\d{4}/.test(date) && isValid(parse(date, { isEUFormat: true }))
        },
        checkValidDateTime(dateTime) {
            if (this.is_japanese) {
                return (
                    /\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}/.test(dateTime) &&
                    isValid(new Date(dateTime)) &&
                    this.checkValidTime(dateTime.substr(-5))
                )
            }

            return (
                /\d{2}.\d{2}.\d{4} \d{2}:\d{2}/.test(dateTime) &&
                isValid(parse(dateTime, { isEUFormat: true })) &&
                this.checkValidTime(dateTime.substr(-5))
            )
        },
        checkValidTime(time) {
            if (!time) return false

            const hours = time.substring(0, 2)
            const minutes = time.substring(3, 5)

            if (isNaN(hours) || isNaN(minutes) || hours > 23 || minutes > 59 || time.length !== 5) {
                return false
            }

            return true
        },
        checkDatePeriod(dateTime) {
            const formattedDate = parse(dateTime)

            if (this.allowedDates) {
                let result = false

                for (const allowedDate of this.allowedDates) {
                    if (isSameDay(formattedDate, allowedDate)) result = true
                }

                return result
            }

            if (this.maxDate && !this.minDate) {
                return (
                    isBefore(formattedDate, this.maxDate) || isSameDay(formattedDate, this.maxDate)
                )
            }

            if (this.minDate && !this.maxDate) {
                return (
                    isAfter(formattedDate, this.minDate) || isSameDay(formattedDate, this.minDate)
                )
            }

            if (this.minDate && this.maxDate) {
                if (this.minDate > this.maxDate) return false
                if (isSameDay(formattedDate, this.minDate)) return true
                if (isSameDay(formattedDate, this.maxDate)) return true

                return isWithinRange(formattedDate, this.minDate, this.maxDate)
            }

            return true
        },
        checkAutoFill() {
            if (this.mode === 'time' && this.localValue) {
                if (this.localValue.length <= 3) {
                    const hours = Number(this.localValue.substring(0, 2))

                    if (hours >= 0 && hours < 24) {
                        if (hours < 10) this.localValue = `0${hours}:00`
                        else this.localValue = `${hours}:00`
                    }
                }
            }
        },
        onFocus() {
            this.focus = true
        },
        onBlur() {
            this.focus = false
            this.dirty = true

            this.checkAutoFill()
        },
        allowedDatesFunction(date) {
            return this.allowedDates.find(item => isSameDay(item, date))
        },
    },
}
</script>

<template>
    <div class="k-date-field">
        <div class="k-date-field__container">
            <div v-if="localValue" class="field-placeholder">
                <span>{{ localValue }}</span>
                {{ field_placeholder.slice(localValue.length) }}
            </div>
            <v-menu
                v-model="showDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                :disabled="mode === 'time'"
                transition="scale-transition"
                min-width="290px"
                offset-y>
                <template #activator="{ on }">
                    <v-text-field
                        v-model="localValue"
                        v-on="on"
                        v-maska="mask"
                        v-bind="$attrs"
                        :label="label_text"
                        :prepend-icon="prependIcon"
                        :disabled="disabled"
                        :clearable="clearable"
                        :hint="hint"
                        :persistent-hint="persistentHint"
                        :placeholder="field_placeholder"
                        :persistent-placeholder="persistentPlaceholder"
                        :rules="dirty || fastValid ? [...rules, ...local_rules] : undefined"
                        :maxlength="maxlength"
                        @blur="onBlur"
                        @focus="onFocus"
                        class="first-capitalize">
                        <template #append-outer>
                            <v-menu
                                v-if="items && items.length"
                                v-model="showItems"
                                max-height="200">
                                <template #activator="{ on }">
                                    <div v-on="on" class="d-flex align-items-center pointer">
                                        <span
                                            class="text-lowercase text-caption grey--text"
                                            style="white-space: nowrap">
                                            {{ itemsText }}
                                        </span>
                                        <v-icon>arrow_drop_down</v-icon>
                                    </div>
                                </template>
                                <v-list class="text-capitalize py-0">
                                    <v-list-item
                                        v-for="item of items"
                                        :key="item.value"
                                        @click="$emit('input', item.value)">
                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <div v-else style="width: 0"></div>
                        </template>
                    </v-text-field>
                </template>

                <v-date-picker
                    v-if="mode === 'date' || mode === 'datetime'"
                    :value="dateLocalValue"
                    :min="min_date"
                    :max="max_date"
                    :locale="$root.locale"
                    :disabled="disabled"
                    :allowed-dates="allowedDates && allowedDatesFunction"
                    @change="onDatePickerChange" />
            </v-menu>
        </div>
    </div>
</template>

<style lang="scss">
@import 'vuetify/src/styles/styles.sass';

.k-date-field {
    &__container {
        position: relative;

        .field-placeholder {
            position: absolute;
            top: 17px;
            left: 2px;
            font-size: 16px;
            color: map-get($grey, 'base');
            display: flex;
            span {
                opacity: 0;
            }
        }
        .v-input {
            &__append-outer {
                display: none;
            }
        }
    }
}
</style>
