<script>
import { mapGetters } from 'vuex'

import GlobalHeader from '@/components/header.vue'
import GlobalFooter from '@/components/footer.vue'
import GlobalSidebar from '@/components/sidebar.vue'
import Kustomer from '@/components/Kustomer/Kustomer.vue'
import CookieConsent from '@/components/cookieConsent.vue'

import Error500 from '@/components/errors/500.vue'
import Error404 from '@/components/errors/404.vue'
import Error403 from '@/components/errors/403.vue'

export default {
    components: {
        GlobalHeader,
        GlobalFooter,
        GlobalSidebar,
        Error500,
        Error404,
        Error403,
        Kustomer,
        CookieConsent,
    },
    data() {
        return {
            drawer: null,
            error: false,
        }
    },
    props: {
        hideSidebar: { type: Boolean, default: false },
        withoutSidebar: { type: Boolean, default: false },
        hideAccount: { type: Boolean, default: false },
    },
    created() {
        if (this.hideSidebar) this.drawer = false
    },
    computed: {
        ...mapGetters(['is_squareup_mode']),
        loading() {
            return this.$store.state.loading
        },
        errorPage() {
            return this.$store.state.errorPage
        },
        stateError() {
            return this.$store.state.error
        },
        has_member_rights() {
            return this.$store.getters.has_member_rights
        },
        editor_mode() {
            return this.$store.getters.editor_mode
        },
        drawer_mini: {
            set(val) {
                this.$store.commit('SET_WORKSPACE_DRAWER_MINI', val)
            },
            get() {
                return this.$store.state.workspaces.drawer_mini
            },
        },
    },

    watch: {
        stateError(val) {
            // false will come from our commit, but null means we need to hide snackbar
            if (val === false) return
            this.error = val
            this.$nextTick(() => {
                this.$store.commit('SET_ERROR', false)
            })
        },
    },
}
</script>

<template>
    <v-app class="main" id="inspire">
        <!-- z-index has to be changed to 2 in order to overlap bottomNavigation -->
        <global-header
            :without-sidebar="withoutSidebar"
            :hide-account="hideAccount"
            @toggleDrawer="drawer = !drawer"
            @language="$root.changeLanguage"
            @help="$refs.kustomer.toggle()" />
        <div class="main-layout-content">
            <v-navigation-drawer
                v-if="has_member_rights && !editor_mode && !withoutSidebar"
                v-model="drawer"
                :fixed="$vuetify.breakpoint.mdAndDown"
                :absolute="$vuetify.breakpoint.lgAndUp"
                :mini-variant="drawer_mini"
                width="280"
                mini-variant-width="44"
                style="z-index: 2"
                height="100%"
                clipped
                app>
                <global-sidebar />
            </v-navigation-drawer>
            <v-main v-show="loading">
                <v-container>
                    <v-row align="center" justify="center"><spinner /></v-row>
                </v-container>
            </v-main>
            <v-main v-show="!loading" style="background-color: #f7f8ff">
                <component
                    v-if="errorPage && errorPage.code"
                    :is="'Error' + errorPage.code"
                    :error="errorPage" />
                <slot v-else />
            </v-main>
        </div>
        <global-footer @language="$root.changeLanguage" @help="$refs.kustomer.toggle()" />
        <v-snackbar bottom :timeout="-1" :value="error" style="padding: 0">
            <span class="first-capitalize">{{ error }}</span>
            <template #action="{ attrs }">
                <v-btn
                    text
                    dark
                    v-bind="attrs"
                    class="danger--text text-capitalize"
                    @click="error = false">
                    {{ $t('dismiss') }}
                </v-btn>
            </template>
        </v-snackbar>
        <kustomer ref="kustomer" />
        <cookie-consent />
    </v-app>
</template>

<style lang="scss" scoped>
.main-layout-content {
    display: flex;
    position: relative;
    flex: 1;
}
</style>
