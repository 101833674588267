import api from '@/api'

const state = {
    verification: null,
    balance: null,
}

const mutations = {
    RESET(state) {
        state.verification = null
        state.balance = null
    },
    SET_VERIFICATION(state, data) {
        state.verification = data
    },
    SET_BALANCE(state, data) {
        state.balance = data
    },
}

const getters = {
    is_custom_connect(localState, getters, state) {
        return (
            getters.has_payment_connected &&
            state.account.user.settings &&
            state.account.user.connect_id &&
            (!state.account.user.settings.connect_type ||
                state.account.user.settings.connect_type == 'custom')
        )
    },
    is_standard_connect(localState, getters, state) {
        return (
            getters.has_payment_connected &&
            state.account.user.settings &&
            state.account.user.connect_id &&
            state.account.user.settings.connect_type &&
            state.account.user.settings.connect_type == 'standard'
        )
    },
    has_payment_connected(localState, getters, state) {
        return !!state?.account?.user?.has_payment_connected
    },
    is_squareup(localState, getters, state) {
        return state.account.user?.application_id && state.account.user.location_id
    },
    is_stripe(localState, { is_standard_connect, is_custom_connect }) {
        return is_custom_connect || is_standard_connect
    },
    has_verification_issues({ verification }, { is_custom_connect }) {
        const hasVerificationIssues =
            verification &&
            ['currently_due', 'eventually_due', 'past_due'].some(property => {
                const array = verification[property]
                return Array.isArray(array) && array.length > 0
            })
        return is_custom_connect && hasVerificationIssues
    },
    is_connect_business_info_ok({ verification }, { is_custom_connect }) {
        const hasVerificationRequirements =
            verification &&
            ['currently_due', 'eventually_due', 'past_due'].some(property => {
                const array = verification[property]
                return (
                    Array.isArray(array) &&
                    array.filter(
                        s =>
                            !String(s).includes('.verification.document') &&
                            !String(s).includes('external_account')
                    ).length > 0
                )
            })
        return is_custom_connect && !hasVerificationRequirements
    },
    is_connect_bank_details_ok({ verification }, { is_custom_connect }) {
        const verificationSucceed =
            verification &&
            ['currently_due', 'eventually_due', 'past_due'].every(property => {
                const array = verification[property]
                return !(Array.isArray(array) && array.includes('external_account'))
            })
        return is_custom_connect && verificationSucceed
    },
    is_connect_plans_ok(localState, getters, state) {
        const products = state.products && state.products.list
        // secons step finished if user has managed product with managed plan
        return (
            Array.isArray(products) &&
            products.filter(product => !!product.managed && Array.isArray(product.plans))
                .length > 0
        )
    },
    needs_connect_verification_document({ verification }, { is_custom_connect }) {
        const hasDue =
            verification &&
            ['currently_due', 'eventually_due', 'past_due'].some(property => {
                const array = verification[property]
                return (
                    Array.isArray(array) &&
                    array.some(s => String(s).includes('.verification.document'))
                )
            })
        return (
            is_custom_connect &&
            (hasDue || !verification || verification.verification_status !== 'verified')
        )
    },
}

const actions = {
    createConnectAccount({ dispatch }, data) {
        return api.post('/connect/', data).catch(e => dispatch('handleError', e))
    },
    updateConnectAccount({ dispatch }, data) {
        return api.patch(`/connect/`, data).catch(e => dispatch('handleError', e))
    },
    verifyConnectAccount({ dispatch }, data) {
        return api.post(`/connect/verification`, data).catch(e => dispatch('handleError', e))
    },
    getVerification({ commit, dispatch, getters }) {
        if (!getters.is_custom_connect) {
            return Promise.resolve(true)
        }
        return api
            .get('/connect/verification')
            .then(({ verification }) => {
                commit('SET_VERIFICATION', verification)
                return verification
            })
            .catch(e => dispatch('handleError', e))
    },
    getBankDetails({ dispatch }) {
        return api
            .get('/user/bank/details')
            .then(({ bank_account }) => bank_account)
            .catch(e => dispatch('handleError', e))
    },
    createBankDetails({ dispatch }, data) {
        return api.post('/user/bank/details', data).catch(e => dispatch('handleError', e))
    },
    getCurrentConnectBalance({ commit, dispatch }) {
        return api
            .get('/connect/balance/current')
            .then(({ balance }) => {
                commit('SET_BALANCE', balance)
                return balance
            })
            .catch(e => dispatch('handleError', e))
    },
    listConnectBalance({ dispatch }) {
        return api
            .get('/connect/balance')
            .then(({ balance }) => balance)
            .catch(e => dispatch('handleError', e))
    },
    setupCustomerIntent({ dispatch }) {
        return api
            .post('/pos/intent', {})
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
    getters,
}
