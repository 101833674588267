<script>
export default {
    name: 'KUnlockWorkspace',
    props: {
        value: {
            type: Boolean,
            required: true,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            unlockData: {
                password: null,
            },
            proxy: false,
        }
    },

    watch: {
        value(val) {
            this.proxy = val
        },
        proxy(val) {
            this.$emit('input', val)
        },
    },

    tasks(t) {
        return {
            unlock: t(async function () {
                this.$store.dispatch('resetError')
                if (!this.unlockData.password) return
                this.$emit('update:loading', true)
                await this.$store.dispatch('unlockWorkspace', this.unlockData)
                this.proxy = false
                this.$store.dispatch('getUser')
            })
                .flow('drop')
                .onFinish(() => {
                    this.$emit('update:loading', false)
                }),
        }
    },

    methods: {},
}
</script>

<template>
    <k-dialog v-model="proxy" persistent max-width="600">
        <v-card>
            <v-card-title class="text-h6 text-capitalize">
                {{ 'unlock encrypted data' | translate }}
                <v-spacer />
                <v-btn text small icon @click="proxy = false" class="ma-0">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="py-0">
                <v-text-field
                    v-model="unlockData.password"
                    id="password"
                    class="text-capitalize"
                    :label="'password' | translate"
                    type="password" />
            </v-card-text>
            <v-card-actions class="pa-4">
                <v-spacer />
                <v-btn @click="proxy = false" color="primary" class="btn-danger-hover" text>
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn color="primary" @click="unlock.run()" :loading="unlock.isActive">
                    {{ 'submit' | translate }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </k-dialog>
</template>

<style lang="scss" scoped></style>
