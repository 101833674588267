<script>
import { getTimeDurationObjectByString, UNIT_OF_TIME } from '@/utils/time-duration'

export default {
    props: {
        label: String,
        onlyUnitsOfTime: Array,
        customUnitsOfTime: Object,
        withZero: Boolean,
        withAfter: Boolean,
        rules: {
            type: Array,
            required: false,
        },
        readonly: Boolean,
        suffix: String,
        hint: String,
        hideDetails: [Boolean, String],
        outlined: Boolean,
    },
    data() {
        return {
            count: 1,
            unitOfTime: 'hours',
            initialized: false,
            beforeAfter: '',
        }
    },
    created() {
        if (this.onlyUnitsOfTime) this.unitOfTime = this.onlyUnitsOfTime[0]

        this.setDefaultCount()

        if (this.$attrs.value) {
            // this.count = this.$attrs.value.count
            // this.unitOfTime = this.$attrs.value.unit_of_time
            this.beforeAfter = this.$attrs.value.startsWith('-') ? '-' : ''
            const timeDuraionObject = getTimeDurationObjectByString(this.$attrs.value)

            if (timeDuraionObject) {
                this.unitOfTime = timeDuraionObject.unitOfTime
                this.count = timeDuraionObject.count
            }
        }

        this.$emit('input', this.result)

        setTimeout(() => (this.initialized = true))
    },
    watch: {
        unitOfTime() {
            if (this.initialized) this.setDefaultCount()
        },
    },
    computed: {
        unit_of_time() {
            return (
                (this.customUnitsOfTime && this.customUnitsOfTime[this.unitOfTime]) ||
                UNIT_OF_TIME[this.unitOfTime]
            )
        },
        count_items_length() {
            return this.unitOfTime ? this.unit_of_time.maxCount / this.unit_of_time.step : 0
        },
        count_items() {
            const length = this.count_items_length + (this.withZero ? 1 : 0)
            const countItems = [...Array(length)].map((item, index) => {
                return (index + (this.withZero ? 0 : 1)) * this.unit_of_time.step
            })

            if (this.unit_of_time.additional) {
                countItems.push(...this.unit_of_time.additional)
                countItems.sort((a, b) => a - b)
            }

            return countItems
        },
        unit_of_time_items() {
            let list = Object.keys({
                ...UNIT_OF_TIME,
                ...(this.customUnitsOfTime ? this.customUnitsOfTime : {}),
            })

            if (this.onlyUnitsOfTime) {
                list = list.filter(unitOfTime => this.onlyUnitsOfTime.includes(unitOfTime))
            }

            return list.map(unitOfTime => ({
                label: this.$t(UNIT_OF_TIME[unitOfTime].label, this.count || 0),
                value: unitOfTime,
            }))
        },
        before_after() {
            return [
                { value: '', label: this.$t('before') },
                { value: '-', label: this.$t('after') },
            ]
        },
        result() {
            return `${this.beforeAfter}${this.count} ${this.unitOfTime}`
        },
    },
    methods: {
        onChange() {
            this.$emit('input', this.result)

            this.$emit('changed', {
                count: this.count,
                unit: this.unitOfTime,
            })
        },
        setDefaultCount() {
            if (this.count_items.indexOf(this.count) !== -1) return
            if (this.withZero) this.count = 0
            else this.count = this.unit_of_time.step

            this.$emit('input', this.result)

            this.$emit('changed', {
                count: this.count,
                unit: this.unitOfTime,
            })
        },
        getCheckRulesResult() {
            if (!this.rules) return

            for (const rule of this.rules) {
                const checkRulesResult = rule(this.result)

                if (checkRulesResult !== true) return checkRulesResult
            }
        },
    },
}
</script>

<template>
    <v-row class="time-duration-input" dense>
        <k-text-field
            :value="result"
            :rules="rules"
            :hint="hint"
            :persistent-hint="!!hint"
            :hide-details="hideDetails"
            class="time-duration-input__fake-input"
            dense />
        <v-col cols="12" sm="4">
            <v-select
                v-model="count"
                :label="label"
                :items="count_items"
                :readonly="readonly"
                :hide-details="hideDetails"
                :suffix="unit_of_time_items.length > 1 ? '' : $t(unit_of_time.label, { count })"
                :outlined="outlined"
                @change="onChange"
                class="text-capitalize"
                dense />
        </v-col>
        <v-col v-if="unit_of_time_items.length > 1" cols="12" sm="4">
            <v-select
                v-model="unitOfTime"
                :items="unit_of_time_items"
                :readonly="readonly"
                :suffix="suffix"
                :hide-details="hideDetails"
                :outlined="outlined"
                @change="onChange"
                item-value="value"
                item-text="label"
                dense />
        </v-col>
        <v-col v-if="withAfter" cols="12" :sm="unit_of_time_items.length > 1 ? 4 : 8">
            <v-select
                v-model="beforeAfter"
                :items="before_after"
                :readonly="readonly"
                :hide-details="hideDetails"
                :outlined="outlined"
                @change="onChange"
                item-value="value"
                item-text="label"
                dense />
        </v-col>
    </v-row>
</template>

<style lang="scss">
@import 'vuetify/src/styles/styles.sass';

.time-duration-input {
    position: relative;
    &__details {
        margin-top: -12px;
    }
    &__fake-input {
        position: absolute;
        top: 12px;
        left: 12px;
        right: 0;
        bottom: 0;
        .v-input__slot {
            &::after,
            &::before {
                display: none;
            }
        }
        input {
            visibility: hidden;
        }

        &.error--text ~ div .v-input {
            .v-label {
                color: map-get($red, 'base') !important;
            }
            // &.v-text-field--outlined {
            fieldset {
                border: 2px solid map-get($red, 'base') !important;
            }
            // }
        }
    }
    &__fields {
        z-index: 1;
    }
}
.select-flex-right {
    .v-select__selections {
        flex-direction: row-reverse;
    }
    .v-select__selection--comma {
        margin: 7px 0px 7px 0;
    }
}
</style>
