<script>
import axios from 'axios'

import KustomerSuccess from './SuccessMessage.vue'

export default {
    props: ['feedback', 'params', 'labels'],

    data() {
        return {
            email: null,
            message: null,
            isLoading: false,
            displaySuccessMessage: false,
            screenshot: undefined,
        }
    },

    computed: {
        origin_user() {
            return this.user.origin || this.user
        },
        user() {
            return this.$store.state.account.user
        },
        label() {
            return (
                (this.feedback &&
                    this.labels.feedbacks[this.feedback.type] &&
                    this.labels.feedbacks[this.feedback.type].label) ||
                'unknown'
            )
        },
        is_guest() {
            return this.user.email === undefined
        },
    },

    methods: {
        submit() {
            this.isLoading = true
            this.sendFeedback()
        },
        back() {
            this.displaySuccessMessage = false
            this.message = null
            this.$emit('unselected')
        },
        sendFeedback() {
            let user = {}
            if (this.origin_user) {
                user = { ...this.origin_user }
            }
            if (this.is_guest) {
                user.email = this.email
            }
            const data = new FormData()
            data.append('type', this.feedback.type)
            data.append('source', 'frontend')
            data.append('message', this.message)
            data.append(
                'viewport[width]',
                Math.max(document.documentElement.clientWidth, window.innerWidth || 0).toString()
            )
            data.append(
                'viewport[height]',
                Math.max(document.documentElement.clientHeight, window.innerHeight || 0).toString()
            )
            Object.keys(user).forEach(key => data.append(`viewport[user][${key}]`, user[key]))
            if (this.$refs.screenshot.files.length) {
                data.append('screenshot', this.$refs.screenshot.files[0])
            }
            axios
                .request({
                    url:
                        import.meta.env.VITE_BASE_URI.replace('/api', '') +
                        '/kustomer-api/feedback',
                    method: 'post',
                    headers: { 'Content-Type': 'multipart/form-data' },
                    data,
                })
                .then(() => {
                    this.isLoading = false
                    this.displaySuccessMessage = true
                })
                .catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('handleError', error)
                })
        },
    },

    components: {
        KustomerSuccess,
    },
}
</script>
<template>
    <section class="kustomer-form" :class="{ 'is-open': feedback }">
        <div v-if="feedback && !displaySuccessMessage" class="kustomer-back" @click="back">
            <img src="/assets/kustomer/back.svg" alt="Return" />
            <h2>{{ $t(label) }}</h2>
        </div>

        <div v-if="feedback && !displaySuccessMessage">
            <form @submit.prevent="submit">
                <textarea
                    :style="{ 'border-bottom-color': params.colors.primary }"
                    name="message"
                    id="message"
                    :placeholder="$t(labels.placeholder)"
                    v-model="message"
                    required />
                <input
                    v-if="is_guest"
                    :style="{ 'border-bottom-color': params.colors.primary }"
                    type="email"
                    name="email"
                    id="email"
                    v-model="email"
                    :placeholder="$t(labels.emailPlaceholder)"
                    required
                    spellcheck="false"
                    autocomplete="email"
                    autocorrect="off"
                    autocapitalize="off" />
                <input
                    :style="{
                        'border-bottom-color': params.colors.primary,
                        'line-height': '30px',
                        'padding-bottom': '15px',
                    }"
                    type="file"
                    name="screenshot"
                    ref="screenshot"
                    :placeholder="$t(labels.screenshotPlaceholder)"
                    accept="image/png, image/jpeg, image/jpg"
                    :multiple="false" />
                <button
                    type="submit"
                    class="text-capitalize"
                    :style="{ 'background-color': params.colors.primary }"
                    :class="{ 'is-loading': isLoading }"
                    :disabled="isLoading">
                    {{ $t(labels.button) }}
                </button>
            </form>
        </div>
        <kustomer-success v-if="displaySuccessMessage" :message="labels.success" />
    </section>
</template>

<style lang="scss">
.kustomer-back {
    img {
        float: left;
    }

    h2 {
        display: block;
        float: right;
        margin-top: 5px !important;
    }
}
</style>
